import React from "react"
import {Row, Col, Container, Form, Button} from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/contact.css"

import logoIcon from '../images/sprite_app_icon_equoo_rounded.png'
import joyStand from '../images/joy_standing_2.png'


const AboutEquoo = () => (
    <Layout pageInfo={{
        pageName: "contact-us"
    }}>
        <Seo
            title="Letter from Founder"
            keywords={[`equoogame`, `mental health`]}/>
        <section id="about-equoo" style={{backgroundColor: "#E0D2EC"}}>
            <Row style={{paddingTop: "3%"}}>
            <Col
          lg={{ span: 1, offset: 2 }} className="d-none d-lg-block"
        >
            <Row>
            <img
            src={logoIcon}
            alt="Logo"
            style={{ width: "100%", height: "auto", marginBottom: "0px" }}
          />
            </Row>
            <Row style={{paddingTop: "20%"}}>
            <img
            src={joyStand}
            alt="Joy"
            style={{ width: "100%", height: "auto", marginBottom: "0px" }}
          />
            </Row>

        </Col>
        <Col lg={{ span: 7, offset: 0 }} xs={{ span: 10, offset: 0 }} style={{marginLeft: "5%", marginRight: "2%"}} >
            <h1 style={{textAlign: "center"}}>Letter from the CEO </h1>
            <p>Wouldn't it be wonderful to have a tool that teaches your employees the critical skills to improve their emotional health? </p>
            <p>One in four adults are affected by mental illness with two-thirds of people with mental health conditions not receiving the 
                care they need. A majority of the workforce are lacking the mental and emotional skills they need to perform at their best. 
                This negatively impacts their daily engagement with their work, presence at the office and ultimately, their value to their employer.  </p>
                <p>The mental health of an individual starts with their emotional health and the ability to cope with both positive and negative emotions, 
                    which includes your awareness of them. With 40% feeling less resilient than prior to 2020 and 26% saying they are struggling to cope 
                    at work, it's not a surprise that 40% of UK employees say they will look for a new job if their employer doesn't do more to support 
                    their mental and emotional health. </p>
            <p>How do you equip an entire workforce with the emotional tools they need to thrive at work and in their personal lives? </p>
            <p>With hundreds of tools available, organisations have invested millions in wellness platforms but are still lacking the evidence to 
                demonstrate the improvement in their people's health. Meanwhile, employees wishing to take their mental wellbeing into their own 
                hands typically find themselves waiting six months or more for access to psychological 'talking therapies'.  </p>
            <p>What if it were possible to target mental and behavioral outcomes in adults in a completely new way? </p>
            <p>Teaming up with world-leading academics and mental health professionals, eQuoo has created an evidence-based emotional health 
                game specifically designed to address the emotional and behavioral challenges of life. We have pinpointed the critical skills 
                lacking in adults who struggle to function at their best in their personal and professional lives. </p>
            <p>eQuoo is a digital health tool that was created by a team of world-leading game designers, teaches the following critical skills in an 
                approachable and enjoyable format. The skills are based on what has been clinically proven has the highest impact on mental health and behavioural issues: </p>
                <ul style={{marginLeft: "0px", paddingLeft: "0px"}}>
                    <li> <b>Resilience: Lack of resilience is the single most impactful factor leading to mental ill-health</b> </li>
                    <li> <b>Interpersonal Relationship Skills: Lacking the skills needed to form and maintain relationships leads to 
                        poor performance at work, troubled interactions with colleagues, and absenteeism </b> </li>
                    <li> <b>Personal Growth: Lacking the ability to set goals and perform unpleasant or uninteresting duties 
                            has a huge impact on both voluntary turnover and absenteeism  </b> </li>
                    <li> <b>Anxiety and Depression: Suffering from Anxiety and/or Depression is the number one cause of absenteeism </b> </li>
                        
                </ul>
            <p>A 5-week clinical programme (3-armed RTC) found that playing the eQuoo game not only significantly improved emotional health but resulted in an effect three 
                times greater than that of an antidepressant. By using psychological principles with emotional bids, storytelling, and gamification, eQuoo has achieved participation 
                of over 60% and retention rates of up to 90%, tackling the high drop- off rates that plague most mobile health services. </p>
            <p>The team at eQuoo are on a mission to improve the emotional health of people everywhere using evidence-based mobile prevention and early intervention. With 1 in 4 
                suffering from mental ill health and 4 in 4 needing support with their emotional health, the time is now to bring a proven and preventative digital health tool to your team. </p>

            <p style={{paddingTop: "3%", marginBottom: "5px"}}> <b>Silja Litvin </b> </p>
            <p style={{marginBottom: "5px"}}>Clinical Psychologist </p>
            <p style={{marginBottom: "3%"}}>CEO and Founder of PsycApps</p>
        </Col>
            </Row>

        </section>
        <section></section>
        <hr
            style={{
            height: "1px",
            backgroundColor: 'black',
            color: "black"
        }}/>
    </Layout>
)

export default AboutEquoo
